import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { useQueryClient } from "react-query";
import DataTable from './DataTable';
import { InitDataTable } from './DataTable';
import { AddDataTableField, AddLinkTableField } from './DataTable';
import { FormatValue } from './FormattingUtilities';
import "../css/UserList.css";
import "../css/ShimmerBox.css";
import { GetUrlBaseV2 } from "./GetUrlBaseV2";
import { QueryKeys } from "./QueryKeys";
import { CircularProgress } from '@mui/material';

const queryKeys = QueryKeys();


function UserAuctionList(props) {
    //const [lenderId] = useState(props.lenderId || -2);
    const [title] = React.useState(props.title || 'User Auction List');
    const queryClient = useQueryClient();
    const urlBase = GetUrlBaseV2();
    const { status, data, error /*, isFetching*/ } = GetAuctions();

    const GetAllAuctions = async () => {
        const { data } = await axios.get(
            `${urlBase}/api/Auction`
        );


        return data;
    };

    function GetAuctions() {
        const [intervalMs/*, setIntervalMs*/] = React.useState(100000);
        return useQuery([queryKeys["AuctionList"]], () => GetAllAuctions(), {
            enabled: true,
        }
            ,
            {
                // Refetch the data every minute
                refetchInterval: intervalMs,
            },

        );
    }

    if (status === "loading") return <div><CircularProgress /></div>;
    if (error) return "An error has occurred: " + error.message;

    let columns = prepareDataTable();
    let rows = formatRows(data);

    function prepareDataTable() {
        let baseColumns = InitDataTable();
        baseColumns = AddLinkTableField(baseColumns, 'id', 'Action', 120, '', false, 'AuctionViewComponentRoot');
        baseColumns = AddDataTableField(baseColumns, 'loanCutoffPct', 'loanCutoffPct', 120, '', true, '');
        baseColumns = AddDataTableField(baseColumns, 'thresholdAmountToCompletePool', 'thresholdAmountToCompletePool', 130, '', true, '');
        baseColumns = AddDataTableField(baseColumns, 'minCreditScore', 'minCreditScore', 130, '', true, '');
        baseColumns = AddDataTableField(baseColumns, 'maxCreditScore', 'maxCreditScore', 90, '', true, '');
        baseColumns = AddDataTableField(baseColumns, 'minTimeSpan', 'minTimeSpan', 90, '', true, '');
        baseColumns = AddDataTableField(baseColumns, 'maxTimeSpan', 'maxTimeSpan', 90, '', true, '');
        baseColumns = AddDataTableField(baseColumns, 'minLoanAmount', 'minLoanAmount', 90, '', true, '');
        baseColumns = AddDataTableField(baseColumns, 'maxLoanAmount', 'maxLoanAmount', 90, '', true, '');
        baseColumns = AddDataTableField(baseColumns, 'loanOverhead', 'loanOverhead', 90, '', true, '');
        baseColumns = AddDataTableField(baseColumns, 'createDateTime', 'createDateTime', "200", '', true, '');
        return baseColumns;
    }

    function MkLink(row) {
        return JSON.stringify({ "auctionId": row.auctionId });
    }

    function formatRows(rows) {
        let result = rows.map(row => ({
            id: MkLink(row),
            loanCutoffPct: (row.loanCutoffPct < 0) ? 'None Yet' : FormatValue('%', row.loanCutoffPct),
            thresholdAmountToCompletePool: FormatValue('$$', row.auctionPool.thresholdAmountToCompletePool),
            minCreditScore: row.auctionPool.minCreditScore,
            maxCreditScore: row.auctionPool.maxCreditScore,
            minTimeSpan: row.auctionPool.minTimeSpan,
            maxTimeSpan: row.auctionPool.maxTimeSpan,
            minLoanAmount: FormatValue('$$', row.auctionPool.minLoanAmount),
            maxLoanAmount: FormatValue('$$', row.auctionPool.maxLoanAmount),
            loanOverhead: FormatValue('%', row.auctionPool.loanOverhead),
            createDateTime: row.createDateTime
        }));
        return result;
    }

    return (
        <div className="bigPadding">
            <div className="title1">{title}</div>
            <div className="top-spacer">&nbsp;</div>
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable rows={rows} columns={columns} />
                </div>
            </div>
            <div className="bottom-spacer">&nbsp;</div>
        </div>
    );

}


export default UserAuctionList;

