//import { isCursorAtEnd } from "@testing-library/user-event/dist/utils";
import axios from "axios";
//import jwt_decode from "jwt-decode";
import { GetUrlBaseV2 } from "../components/GetUrlBaseV2";

const urlBase = GetUrlBaseV2();

class AuthService {
  login(username, password) {
    this.logout();
    
    return axios
      .post(urlBase + "/api/Login", {
        username,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
            var token = response.data.accessToken;  /// jwt token;
            //var decoded = jwt_decode(token);
            localStorage.setItem("accessToken", token);
            localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(urlBase + "/api/signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    try{
        return JSON.parse(localStorage.getItem('user'));
    } catch (error) {
        console.warn('Could not fetch current user due to ' + error);
        return "";
    }

  }

  roleIsUser(){
    const user = this.getCurrentUser();
    if(user)
        return user.roles.includes("User");
    return false;
  }


    roleIsAdmin(){
        const user = this.getCurrentUser();
        if(user)
            return user.roles.includes("Admin");
        return false;
    }

    getEmptyGuid() {
        return "00000000-0000-0000-0000-000000000000";
    }

    getAuctionEntityId() {
        const user = this.getCurrentUser();
        if(user){
            return user.auctionEntityId;
        }
        return this.getEmptyGuid();
    }

}



export default new AuthService();
