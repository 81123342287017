/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import BigSmallTextComponent from './BigSmallTextComponent';
import Vl from './Vl';
import ContinuousSlider from "./ContinuousSlider";
import { FormatValue } from "./FormattingUtilities.js";
import LineRechartComponent from "./line.rechart"
import CurrentAuctionChartComponent from "./CurrentAuctionChartComponent"
import SectionHeaderComponent from "./SectionHeaderComponent";
import "../css/ShimmerBox.css";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";
import { GetUrlBaseV2 } from "./GetUrlBaseV2";
import { QueryKeys } from "./QueryKeys";
import { CircularProgress } from '@mui/material';
import { Button, Stack } from '@mui/material';
import AuthService from "../services/auth.service";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';



const urlBase = GetUrlBaseV2();
const queryKeys = QueryKeys();



function BorrowerComponent(props) {
    const handle = useParams();
    const [borrowerId, setBorrowerId] = React.useState(handle.borrowerId);
    const [auctionItemId, setAuctionItemId] = React.useState(handle.auctionItemId);
    const [auctionId, setAuctionId] = React.useState(handle.auctionId);

    const [loanAmount, setLoanAmount] = useState(0);
    const [loanPercentage, setLoanPercentage] = useState(0);
    const [loanTimeSpan, setLoanTimeSpan] = useState(0);
    const [infoIsDirty, setInfoIsDirty] = useState(true);

    const [navigateUrl, setNavigateUrl] = useState('');
    const emptyGuid = AuthService.getEmptyGuid();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { status, data, error, isFetching } = useBorrower(borrowerId, auctionId, auctionItemId);
    const { state } = useLocation();
    const [monthlyPayment, setMonthlyPayment] = useState(0);

    useEffect(() => {
        navigateUrl &&
            navigate("/UserDashboard", { state: { "auctionEntityId": navigateUrl } })
    });

    useEffect(() => {
        if (state && !auctionId) {
            if (state.auctionEntityId) setBorrowerId(state.auctionEntityId);
            if (state.auctionItemId) setAuctionItemId(state.auctionItemId);
            if (state.auctionId) setAuctionId(state.auctionId);
        }
    }, []);

    const updateBorrower = useMutation(
        (newData) => {
            return axios.put(`${urlBase}/api/BorrowerComponent/${borrowerId}`, newData)
        },
        {
            onSuccess: () => {
                //queryClient.invalidateQueries([queryKeys["Borrower"], auctionId]);
                //queryClient.invalidateQueries([queryKeys["Dashboard"], borrowerId]);
            },
        }
    );



    const getBorrowerById = async (borrowerId, auctionId, auctionItemId) => {
        var target = `${urlBase}/api/BorrowerComponent/${borrowerId}?auctionId=${auctionId}`;
        if (!!auctionItemId) {
            target += `&auctionItemId=${auctionItemId}`;
        }
        const { data } = await axios.get(
            target
        );
        return data;
    };

    function calculateSavings(loanTimeSpan, principal, months, percentage) {

        let payment = loanTimeSpan * calculateMontlyPayment(principal, months, percentage);
        let higherPayment = loanTimeSpan * calculateMontlyPayment(principal, months, percentage + 0.05);
        let delta = higherPayment - payment;

        return delta;
    }

    function calculateMontlyPayment(principal, months, percentage) {
        let result = 0;
        try {
            if (!principal || !months || !percentage)
                return 0;
            result = principal * ((percentage / 12) * Math.pow((1 + percentage / 12), months)) / (Math.pow(1 + percentage / 12, months) - 1);
        }
        catch {
            console.warn('calculateMontlyPayment failed with principal=' + principal + ', months=' + months + ', percentage=' + percentage);
        }
        return result;
    }


    function useBorrower(borrowerId, auctionId, auctionItemId) {
        const [intervalMs/*, setIntervalMs*/] = React.useState(10000);
        return useQuery([queryKeys["Borrower"], auctionId], () => getBorrowerById(borrowerId, auctionId, auctionItemId), {
            enabled: !!auctionId,
        }
            ,
            {
                // Refetch the data every minute
                refetchInterval: intervalMs,
            },
        );
    }

    function updateInfo(name, value) {
        let result = { loanAmount: loanAmount, loanPercentage: loanPercentage, loanTimeSpan: loanTimeSpan };

        switch (name) {
            case 'amount':
                setLoanAmount(value);
                result.loanAmount = value;
                break;
            case 'percentage':
                setLoanPercentage(value);
                result.loanPercentage = value;
                break;
            case 'timeSpan':
                setLoanTimeSpan(value);
                result.loanTimeSpan = value;
        }
        return result;
    }

    function doDelete() {
        exitPoolViaApi();
    }

    function doCommit() {
        if (!borrowerId || borrowerId == emptyGuid) {
            toast.error("You must be logged in to commit.");
            return;
        }
        enterPoolViaApi();
    }

    const exitPoolViaApi = () => {
        var jsonData = {};
        jsonData["AuctionId"] = data.borrowerComponent.auctionId;
        jsonData["AuctionEntityId"] = borrowerId;
        jsonData["AuctionItemId"] = auctionItemId;
        setAuctionId(null);

        exitPoolApi.mutate(jsonData);
        return null;
    }

    const enterPoolViaApi = () => {
        var jsonData = {};
        jsonData["AuctionId"] = data.borrowerComponent.auctionId;
        jsonData["AuctionEntityId"] = borrowerId;
        jsonData["AuctionItemId"] = auctionItemId;
        jsonData["amount"] = loanAmount;
        jsonData["percentage"] = loanPercentage;
        jsonData["timeSpan"] = loanTimeSpan;
        enterPoolApi.mutate(jsonData);
        return null;
    }


    const exitPoolApi = useMutation(
        (newData) => {
            let target = `${urlBase}/api/BorrowerComponent/${borrowerId}`;
            let response = axios.delete(`${urlBase}/api/BorrowerComponent/${borrowerId}`,
                { data: newData })
            return response;
        },
        {
            onSuccess: (data, variables, context) => {
                toast.success("Your request was deleted.");
                //queryClient.invalidateQueries([queryKeys["Borrower"], auctionId]);
                //queryClient.invalidateQueries([queryKeys["Dashboard"], borrowerId]);

                setNavigateUrl(borrowerId);
            },
            onError: (error, variables, context) => {
                if (error && error.response && error.response.data)
                    toast.error('Your action failed due to: ' + JSON.stringify(error.response.data));
                else
                    toast.error('Got an unknown error on the transactions =' + error);
            },
        }

    );




    const enterPoolApi = useMutation(
        (newData) => {
            let response = axios.post(`${urlBase}/api/BorrowerComponent/${borrowerId}`, newData)
            return response;
        },
        {
            onSuccess: (data, variables, context) => {
                let newAuctionItemId = data.data;
                setAuctionItemId(newAuctionItemId);
                toast.success("Your request was entered.");
                queryClient.invalidateQueries([queryKeys["Borrower"], auctionId]);
                //queryClient.invalidateQueries([queryKeys["Dashboard"], borrowerId]);

            },
            onError: (error, variables, context) => {
                if (error && error.response && error.response.data)
                    toast.error('Your action failed due to: ' + error.response.data);
                else
                    toast.error('Got an unknown error on the transactions =' + error);
            },
        }
    );



    function statusMessages(borrowerId, status, error, isFetching) {
        if (error) return "An error has occurred: " + error.message;
        var d = new Date();
        var datetime = d.toLocaleString();
        if (isFetching)
            console.log(datetime + " Background Fetch...");
        return "";
    }

    const notifyParent = (name, value) => {
        let result = updateInfo(name, value);
        var jsonData = {};
        jsonData["AuctionId"] = data.borrowerComponent.auctionId;
        jsonData["AuctionEntityId"] = borrowerId;
        jsonData["AuctionItemId"] = data.borrowerComponent.auctionItemId;
        jsonData["amount"] = result.loanAmount;
        jsonData["percentage"] = result.loanPercentage;
        jsonData["timeSpan"] = result.loanTimeSpan;

        updateBorrower.mutate(jsonData);
        return null;
    }

    function statusReport() {

    }

    function DataIsValid() {
        if (data == null) {
            return -1;
        }
        if (data.borrowerComponent == null) {
            return -2;
        }

        if (infoIsDirty) {
            if (data.borrowerComponent.yourLoanPercentageCurrent > 0) {
                setInfoIsDirty(false);
                setLoanAmount(data.borrowerComponent.yourLoanAmountCurrent);
                setLoanPercentage(data.borrowerComponent.yourLoanPercentageCurrent);
                setLoanTimeSpan(data.borrowerComponent.yourTimeSpanCurrent);
            }
        }
        return 0;
    }

    if (!borrowerId) return <div>No User Specified</div>
    if (isFetching || !borrowerId || status === "loading") return <div><CircularProgress /></div>;
    /*if (isFetching) return "Background Fetch...";*/
    if (error)
        return "An error has occurred: " + error.message;

    let testData = DataIsValid();
    if (testData != 0)
        return (<div><CircularProgress size={20} /> &nbsp; Validating step {testData}</div>);

    return (
        <div className="bigPadding">
            <div><Toaster /></div>
            {statusReport()}
            <h6>&nbsp;</h6>
            <h5>{data.borrowerComponent.yourName} - Borrower Status</h5>
            <h6>&nbsp;</h6>
            <Grid container spacing={1} sx={{ width: 1200 }} columns={2} direction="row">
                <Grid item>
                    <Box sx={{ width: 1200 }} className="shimmer bigPadding">
                        <Grid item>
                            <Box sx={{ width: 1100 }}>
                                <Grid container spacing={5} columns={15}>
                                    <Grid item xs={1} ></Grid>
                                    <Grid item xs={4} >
                                        <ContinuousSlider
                                            minValue={data.borrowerComponent.yourLoanAmountMinimum}
                                            maxValue={data.borrowerComponent.yourLoanAmountMaximum}
                                            value={loanAmount}
                                            title='Loan Amount'
                                            stepSize={1000}
                                            formatStyle='$$'
                                            notifyParent={notifyParent}
                                            notifyParentName='amount'
                                            keyx={loanAmount}
                                        />
                                    </Grid>
                                    <Grid item xs={1} ><Vl /></Grid>

                                    <Grid item xs={4} >
                                        <ContinuousSlider
                                            minValue={data.borrowerComponent.yourLoanPercentageMinimum}
                                            maxValue={data.borrowerComponent.yourLoanPercentageMaximum}
                                            value={loanPercentage}
                                            title='Loan Percent'
                                            stepSize={0.005}
                                            formatStyle='%'
                                            formatLabels={true}
                                            notifyParent={notifyParent}
                                            notifyParentName='percentage'
                                            keyx={loanPercentage}
                                        />
                                    </Grid>
                                    <Grid item xs={1} ><Vl /></Grid>

                                    <Grid item xs={4} >
                                        <ContinuousSlider
                                            minValue={data.borrowerComponent.yourTimeSpanMinimum}
                                            maxValue={data.borrowerComponent.yourTimeSpanMaximum}
                                            value={loanTimeSpan}
                                            title='Time Span'
                                            stepSize={6}
                                            formatStyle=' months'
                                            notifyParent={notifyParent}
                                            notifyParentName='timeSpan'
                                            keyx={loanTimeSpan}
                                        />
                                    </Grid>

                                </Grid>
                            </Box>
                            <Box >
                                <Grid container spacing={1} columns={2} direction="row" sx={{ width: 1100 }} >
                                    <Grid item sx={{ width: 800 }}>
                                        <SectionHeaderComponent line1='Your Numbers' />
                                    </Grid>
                                    <Grid item sx={{ width: 300 }}>
                                        <SectionHeaderComponent line1='Current Pool' />
                                    </Grid>

                                </Grid>
                            </Box>
                            <Grid container spacing={1} columns={2} direction="row">
                                <Box sx={{ width: 950 }}>

                                    <Grid container spacing={0} columns={16}>

                                        <Grid item xs={3} >
                                            <BigSmallTextComponent
                                                line1={FormatValue('%', loanPercentage)}
                                                line2='Interest Rate'
                                                key={loanPercentage} />
                                        </Grid>

                                        <Grid item xs={1} ><Vl /></Grid>

                                        <Grid item xs={3}  >

                                            <BigSmallTextComponent
                                                line1={FormatValue('$', calculateMontlyPayment(loanAmount, loanTimeSpan, loanPercentage))}
                                                line2='MONTHLY PAYMENT'
                                                key={loanAmount + '|' + loanTimeSpan + '|' + loanPercentage} />
                                        </Grid>

                                        <Grid item xs={1}   ><Vl /></Grid>

                                        <Grid item xs={3} >
                                            <BigSmallTextComponent
                                                line1={FormatValue('$', loanTimeSpan * calculateMontlyPayment(loanAmount, loanTimeSpan, loanPercentage))}
                                                line2='REPAYMENT AMOUNT'
                                                key={loanAmount + '|' + loanTimeSpan + '|' + loanPercentage} />
                                        </Grid>

                                        <Grid item xs={1}  ><Vl /></Grid>

                                        <Grid item xs={3} >
                                            <BigSmallTextComponent
                                                line1={FormatValue('$$', calculateSavings(loanTimeSpan, loanAmount, loanTimeSpan, loanPercentage))}
                                                line2='SAVINGS COMPARED TO NATIONAL AVERAGE'
                                                key={loanAmount + '|' + loanTimeSpan + '|' + loanPercentage} />
                                        </Grid>

                                    </Grid>
                                    <Stack spacing={2} direction="row">
                                        <Box sx={{ width: 700 }}>

                                            <CurrentAuctionChartComponent
                                                auctionId={auctionId}
                                                yourPercentage={loanPercentage}
                                                requiredToClose={data.borrowerComponent.requiredToClose}
                                                key={loanAmount + '|' + loanTimeSpan + '|' + loanPercentage}
                                            />
                                        </Box>
                                        <Box sx={{ width: 250 }}>
                                            {!auctionItemId &&
                                                <Button variant="contained" color="success" onClick={doCommit}>
                                                    Commit
                                                </Button>
                                            }
                                            {auctionItemId &&
                                                <Button variant="contained" color="success" onClick={doDelete}>
                                                    Remove My Request
                                                </Button>
                                            }
                                        </Box>

                                    </Stack>


                                </Box>

                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>


        </div>
    );
}

export default BorrowerComponent;

