// src/components/line.rechart.js

import React from "react";
import { LineChart, Line, YAxis, XAxis, /*CartesianGrid,*/ Tooltip, Legend } from 'recharts';

function LineRechartComponent() {

    const data = [
        { "name": "6", "Your Rate": 6000, "National Average": 7000 },
        { "name": "12", "Your Rate": 11040, "National Average": 13800 },
        { "name": "18", "Your Rate": 15840, "National Average": 19800 },
        { "name": "24", "Your Rate": 20160, "National Average": 25200 },
        { "name": "30", "Your Rate": 24000, "National Average": 30000 },
        { "name": "36", "Your Rate": 27360, "National Average": 34200 },
        { "name": "42", "Your Rate": 30240, "National Average": 37800 },
        { "name": "48", "Your Rate": 32640, "National Average": 40800 },
        { "name": "54", "Your Rate": 34560, "National Average": 43200 },
        { "name": "60", "Your Rate": 36000, "National Average": 45000 },
        { "name": "66", "Your Rate": 36960, "National Average": 46200 },
        { "name": "72", "Your Rate": 37440, "National Average": 46800 },

    ];


    return (
        <>
        <h5>National Average Comparison</h5>
        <LineChart width={700} height={400} data={data}
            margin={{ top: 15, right: 30, left: 100, bottom: 5 }}>
            
            <XAxis dataKey="name"  unit=' months '/>
            <YAxis   label={{ value: 'Amount Borrowed', angle: -90, position: 'left', offset: 50  }} />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="Your Rate" stroke="#0789BB"  strokeWidth={5} dot={false} />
            <Line type="monotone" dataKey="National Average" stroke="#c0c0c0"  strokeWidth={5}  dot={false} />
        </LineChart>
        </>
    );

}

export default LineRechartComponent;