import React, { useState } from "react";
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { FormatValue } from "./FormattingUtilities.js";
import "../css/Slider.css";
/*import { TypeOfToString } from "./TypeOfToString.js";*/




const MarkSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.mode === 'dark' ? '#dddddd' : '#dddddd',
    height: 10,
    padding: '15px 0',
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: 'white',
      border: '4px solid #0789bb',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
    },
    '& .MuiSlider-valueLabel': {
      fontSize: 16,
      fontWeight: 'bold',
      top: -6,
      backgroundColor: 'unset',
      color: 'unset',
      '&:before': {
        display: 'none',
      },
      '& *': {
        background: 'transparent',
        color: '#0789bb',
      },
    },
  }));
  







export default function ContinuousSlider(props) {
    const [value, setValue] = useState(props.value || 0);
    const [minValue] = useState(props.minValue || 10000);
    const [maxValue] = useState(props.maxValue || 100000);
    const [title] = useState(props.title || "Title Area");
    const [stepSize] = useState(props.stepSize);
    const [formatStyle] = useState(props.formatStyle);
    const [formatLabels] = useState(props.formatLabels  || false);
    const [notifyParentName] = useState(props.notifyParentName);
    /* also called with childToParent() which lets me alert on a change */

    const handleChange = (event, newValue) => {
        props.notifyParent(notifyParentName, newValue);
        setValue(newValue);

    };

    function valuetext(value) {
        return FormatValue(formatStyle, value);
    }

    function formatMarkLabel(value) {
        if (formatLabels)
            return FormatValue(formatStyle, value);
        return value;
    }

    const marks = [
        {
            value: Number(`${minValue}`),
            label: formatMarkLabel(`${minValue}`),
        },

        {
            value: Number(`${maxValue}`),
            label: formatMarkLabel(`${maxValue}`),
        },
    ];

        /*
        console.log(`Slider called with title=${title}, minValue=${minValue}, maxValue=${maxValue}, stepSize=${stepSize} )` )
        */
        /* Debug options
            {TypeOfToString(props.childToParent)}
            {JSON.stringify(props.childToParent)}
        */

    return (
        <Box sx={{ height: 120 }} className="slider">

            <Box sx={{ height: 60 }} className="title">{title}</Box>
            <MarkSlider value={value} 
                onChange={handleChange}
                marks={marks}
                step={stepSize}
                min={minValue}
                max={maxValue}
                valueLabelDisplay="on"
                valueLabelFormat={valuetext}
            />
        </Box>

    );
}
