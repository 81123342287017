import React,{useState} from "react";
import "../css/BigSmallTextComponent.css";

function BigSmallTextComponent(props) {
    const [line1] = useState(props.line1 || "Line 1 not initted");
    const [line2] = useState(props.line2 || "Line 2 not initted");

    return (
        <div className="bigSmallContainer">
            <div className="line1">
                {line1}
            </div>
            <div className="line2">
                {line2}
            </div>
        </div>
    );
}

export default BigSmallTextComponent;

