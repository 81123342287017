import React,{useState} from "react";
import "../css/SectionHeaderComponent.css";

function SectionHeaderComponent(props) {
    const [line1] = useState(props.line1);

    return (
        <div >
            <div className="sectionHeader">
                {line1}
            </div>   
            <hr/>         
        </div>
    );
}

export default SectionHeaderComponent;

