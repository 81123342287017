import React from 'react';

function Contact (){
return <address>
			You can find us here:<br />
			Top of the world<br />
			Scott's place <br />
			Or just call - you know the number 
		</address>
}

export default Contact;
