import * as CryptoJS from 'crypto-js';

export function aesEncrypt(plainText) {
    var aesSecret = "TLP524255a7f54d2726a951bb39204df";
    var aesIV = "TLP3288699248111";
    var cipherText = aesEncryptWithAllParms(plainText, aesSecret, aesIV);
    return cipherText;
}

export function aesDecrypt(cipherText){
    var aesSecret = "TLP524255a7f54d2726a951bb39204df";
    var aesIV = "TLP3288699248111";
    var plainText = aesDecryptWithParms(cipherText, aesSecret, aesIV);
    return plainText;
}

export function aesEncryptWithAllParms(inText, inKey, inIV) {
    var key = CryptoJS.enc.Utf8.parse(inKey);
    var iv = CryptoJS.enc.Utf8.parse(inIV);

    var cipherText = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(inText), key,
        {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    return cipherText.toString();
}

export function aesDecryptWithParms(inText, inKey, inIV){
    var key = CryptoJS.enc.Utf8.parse(inKey);
    var iv = CryptoJS.enc.Utf8.parse(inIV);

    var decryptedText =  CryptoJS.AES.decrypt(inText, key,
        {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    return decryptedText.toString(CryptoJS.enc.Utf8)
}