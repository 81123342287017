import React, { useState } from "react";
import { DataGrid, GridColDef, GridValueGetterParams, GridToolbar } from '@mui/x-data-grid';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import CircularStatic from '../../src/components/PercentComplete'



/*Usage:
    Prep:
    let columns = InitDataTable();
    columns = AddDataTableField(DataTableColumns,fieldName, headerName, width, type, sortable, linkToPrefix);
    ...
    then call with 
    <DataTable rows=rows, columns=columns/>
*/



export function InitDataTable() {
    return [];
}


/* 
    Adds a new field in the table
    If linkToPrefix is specified, then the current field is used as a link
*/
export function AddDataTableField(DataTableColumns, fieldName, headerName, width, type, sortable) {
    DataTableColumns.push(MkDataField(fieldName, headerName, width, type, sortable, null));
    return DataTableColumns;
}

export function AddDataTableProgressField(DataTableColumns, fieldName, headerName, width, type, sortable) {
    DataTableColumns.push(MkProgressField(fieldName, headerName, width, type, sortable));
    return DataTableColumns;
}

export function AddLinkTableField(DataTableColumns, fieldName, headerName, width, type, sortable, linkToPrefix) {
    DataTableColumns.push(MkDataField(fieldName, headerName, width, type, sortable, linkToPrefix));
    return DataTableColumns;
}



export default function DataTable(props) {
    const [rows] = useState(props.rows);
    const [columns] = useState(props.columns);
    const [showToolbar] = useState(props.showToolbar || false);

    if (!props || !props.rows || !props.columns) {
        return (<div>No Data</div>);
    }

    return (        
        <div style={{ display: 'flex', height: '100%' }}>
            <DataGrid
                autoHeight
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}

                {...showToolbar && { components: { Toolbar: GridToolbar } }}
            />
        </div>
    );
}

/*                components={{ Toolbar: GridToolbar }}*/
/* 
    Makes a new field in the table
    If linkToPrefix is specified, then the current field is used as a link (and it must be the id field)
*/
function MkDataField(fieldName, headerName, width, type, sortable, linkToPrefix) {

    let baseField = {
        field: fieldName,
        headerName: headerName,
        width: width,
        type: type,
        sortable: sortable,
    };

    const linkField = {
        renderCell: (params) => (
            <strong>
                <Link to={MkLinkToField(linkToPrefix)} state={AddStateVariables(params)}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginLeft: 16 }}
                    >
                        Open
                    </Button>
                </Link>
            </strong>
        )
    };



    if (!!linkToPrefix)
        baseField = { ...linkField, ...baseField };

    return baseField;
}

// safely handles circular references
JSON.safeStringify = (obj, indent = 2) => {
    let cache = [];
    const retVal = JSON.stringify(
        obj,
        (key, value) =>
            typeof value === "object" && value !== null
                ? cache.includes(value)
                    ? undefined // Duplicate reference found, discard key
                    : cache.push(value) && value // Store value in our collection
                : value,
        indent
    );
    cache = null;
    return retVal;
};

function reportParams(params, value) {
    if (params.row.id == 5)
        //JSON.safeStringify(params);
        console.log(JSON.safeStringify(params));
    return true;
}

function AddStateVariables(params){
    let fieldName = params.field;
    let rowData = params.row;
    let result = JSON.parse(rowData[fieldName]);
    return result;

}

function MkLinkToField(linkToPrefix) {
//    let fieldName = params.field;
//    let rowData = params.row;
    let result = '/' + linkToPrefix + '/' ;
    return result;
}



function GetPercentage(params) {
    let fieldName = params.field;
    let rowData = params.row;
    let result = rowData[fieldName];
    return result;
}

function MkProgressField(fieldName, headerName, width, type, sortable) {

    let baseField = {
        field: fieldName,
        headerName: headerName,
        width: width,
        type: type,
        sortable: sortable,
    };


    const progressField = {
        renderCell: (params) => (
            <CircularStatic percentage={GetPercentage(params)} />
        )
    };

    baseField = { ...progressField, ...baseField };

    return baseField;
}



