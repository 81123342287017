import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from "axios";
import { useQuery } from "react-query";
import { useQueryClient } from "react-query";
import DataTable from './DataTable';
import { InitDataTable } from './DataTable';
import { AddDataTableField } from './DataTable';
import { FormatValue } from './FormattingUtilities';
import "../css/UserList.css";
import { GetUrlBaseV2 } from "./GetUrlBaseV2";
import { CircularProgress } from '@mui/material';


function UserList(props) {
    //const [lenderId] = useState(props.lenderId || -2);

    const queryClient = useQueryClient();

    const urlBase = GetUrlBaseV2();

    const { status, data, error /*, isFetching*/ } = GetUsers();

    const GetAllUsers = async () => {
        const { data } = await axios.get(
            `${urlBase}/api/AuctionEntity`
        );


        return data;
    };

    function GetUsers() {
        const [intervalMs/*, setIntervalMs*/] = React.useState(100000);
        return useQuery(["UserList"], () => GetAllUsers(), {
            enabled: true,
        }
            ,
            {
                // Refetch the data every minute
                refetchInterval: intervalMs,
            },

        );
    }

    if (status === "loading") return <div><CircularProgress /></div> ;
    if (error) return "An error has occurred: " + error.message;


    let columns = prepareDataTable();
    let rows = formatRows(data);

    function prepareDataTable() {
        let baseColumns = InitDataTable();
        baseColumns = AddDataTableField(baseColumns, 'id', 'Action', 120, '', false, '/UserDashboard');
        baseColumns = AddDataTableField(baseColumns, 'name', 'Name', 140, '', true, '');
        baseColumns = AddDataTableField(baseColumns, 'phoneNumber', 'Phone', 120, 'number', true, '');
        baseColumns = AddDataTableField(baseColumns, 'creditScore', 'Credit Score', 130, 'number', true, '');
        baseColumns = AddDataTableField(baseColumns, 'maximumAmountToLend', 'Max Lend Amount', 90, 'number', true, '');
        baseColumns = AddDataTableField(baseColumns, 'address', 'Address', 400, '', true, '');
        return baseColumns;
    }

    function formatRows(rows) {
        let result = rows.map(row => ({
            ...row,
            id: row.auctionEntityId,
            maximumAmountToLend: FormatValue('$$', row.maximumAmountToLend)
        }));
        return result;
    }

    if (false) {
        return (JSON.stringify(data));

    }


    else {
        return (
            <div >
                <div className="title1" >Management Dashboard User List</div>
                <div className="top-spacer">&nbsp;</div>
                <DataTable rows={rows} columns={columns} showToolbar={true} />
                <div className="bottom-spacer">&nbsp;</div>
            </div>
        );

    }
}

export default UserList;

