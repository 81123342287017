/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import BigSmallTextComponent from './BigSmallTextComponent';
import Vl from './Vl';
import ContinuousSlider from "./ContinuousSlider";
import { FormatValue } from "./FormattingUtilities.js";
import CurrentAuctionChartComponent from "./CurrentAuctionChartComponent"
import SectionHeaderComponent from "./SectionHeaderComponent";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";
import { GetUrlBaseV2 } from "./GetUrlBaseV2";
import { QueryKeys } from "./QueryKeys";
import { CircularProgress } from '@mui/material';
import { Button, Stack } from '@mui/material';
import AuthService from "../services/auth.service";
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import "../css/ShimmerBox.css";
const urlBase = GetUrlBaseV2();
const queryKeys = QueryKeys();


function LenderComponent(props) {
    const handle = useParams();
    const [lenderId, setLenderId] = React.useState(handle.lenderId);
    const [auctionItemId, setAuctionItemId] = React.useState(handle.auctionItemId);
    const [auctionId, setAuctionId] = React.useState(handle.auctionId);

    const [vig] = useState(0.03);
    const [nationalAverageROR] = useState(0.05);
    const [infoIsDirty, setInfoIsDirty] = useState(true);
    const emptyGuid = AuthService.getEmptyGuid();

    const [navigateUrl, setNavigateUrl] = useState('');
    const navigate = useNavigate();

    const queryClient = useQueryClient();
    const { status, data, error, isFetching } = useLender(lenderId, auctionId, auctionItemId);
    const { state } = useLocation();

    function showState(inState) {
        const { data } = inState;
        console.log(JSON.stringify(inState));
    }

    useEffect(() => {
        //console.log(JSON.stringify('state = ' + state));
        navigateUrl &&
            navigate("/UserDashboard", { state: { "auctionEntityId": navigateUrl } })
    }, []);


    useEffect(() => {
        if (state) {
            if (state.auctionEntityId) setLenderId(state.auctionEntityId);
            if (state.auctionItemId) setAuctionItemId(state.auctionItemId);
            if (state.auctionId) setAuctionId(state.auctionId);

        }
    }, []);


    const [loanAmount, setLoanAmount] = React.useState(0);
    const [loanPercentage, setLoanPercentage] = React.useState(0);
    const [loanTimeSpan, setLoanTimeSpan] = React.useState(72);

    const updateLenderApi = useMutation(
        (newData) => {
            return axios.put(`${urlBase}/api/LenderComponent/${lenderId}`, newData)
        },
        {
            onSuccess: () => {
                //queryClient.invalidateQueries([queryKeys["Lender"], auctionId]);
                //queryClient.invalidateQueries([queryKeys["Dashboard"], lenderId]);
            },
        }
    );

    const enterPoolApi = useMutation(
        (newData) => {
            return axios.post(`${urlBase}/api/LenderComponent/${lenderId}`, newData)
        },
        {
            onSuccess: (data, variables, context) => {
                toast.success("Offer Received");
                let newAuctionItemId = data.data;
                setAuctionItemId(newAuctionItemId);
                queryClient.invalidateQueries([queryKeys["Lender"], auctionId]);
                queryClient.invalidateQueries([queryKeys["Dashboard"], lenderId]);

            },
            onError: (error, variables, context) => {
                if (error && error.response && error.response.data)
                    toast.error('Your action failed due to: ' + error.response.data);
                else
                    toast.error('Got an unknown error on the transactions');
            },
        }

    );

    const getLenderById = async (lenderId, auctionId, auctionItemId) => {
        var target = `${urlBase}/api/LenderComponent/${lenderId}?auctionId=${auctionId}`;
        if (!!auctionItemId)
            target += `&auctionItemId=${auctionItemId}`;

        const { data } = await axios.get(
            target
        );
        return data;
    };

    function useLender(lenderId, auctionId, auctionItemId) {
        const [intervalMs/*, setIntervalMs*/] = React.useState(1000);
        return useQuery([queryKeys["Lender"], auctionId], () => getLenderById(lenderId, auctionId, auctionItemId), {
            enabled: !!auctionId,
        }
            ,
            {
                // Refetch the data every minute
                refetchInterval: intervalMs,
            },

        );
    }

    function updateInfo(name, value) {
        let result = { loanAmount: loanAmount, loanPercentage: loanPercentage, loanTimeSpan: loanTimeSpan };
        switch (name) {
            case 'amount':
                setLoanAmount(value);
                result.loanAmount = value;
                break;
            case 'percentage':
                setLoanPercentage(value);
                result.loanPercentage = value;
                break;
            case 'timeSpan':
                setLoanTimeSpan(value);
                result.loanTimeSpan = value;
        }
        return result;

    }


    function calculateMontlyPayment(principal, months, percentage) {
        let result = 0;
        try {
            if (!principal || !months || !percentage)
                return 0;
            result = principal * ((percentage / 12) * Math.pow((1 + percentage / 12), months)) / (Math.pow(1 + percentage / 12, months) - 1);
        }
        catch {
            console.warn('calculateMontlyPayment failed with principal=' + principal + ', months=' + months + ', percentage=' + percentage);
        }
        return result;
    }


    function DataIsValid() {
        if (data == null) {
            return -1;
        }
        if (data.lenderComponent == null) {
            return -2;
        }

        if (infoIsDirty) {
            if (data.lenderComponent.yourLoanPercentageCurrent > 0) {
                setInfoIsDirty(false);
                setLoanAmount(data.lenderComponent.yourLoanAmountCurrent);
                setLoanPercentage(data.lenderComponent.yourLoanPercentageCurrent);
            }
        }

        return 0;
    }




    function statusMessages(lenderId, status, error, isFetching) {
        if (error) return "An error has occurred: " + error.message;
        var d = new Date();
        var datetime = d.toLocaleString();
        if (isFetching)
            console.log(datetime + " Background Fetch...");
        return "";
    }



    const exitPoolApi = useMutation(
        (newData) => {
            let target = `${urlBase}/api/LenderComponent/${lenderId}`;
            let response = axios.delete(`${urlBase}/api/LenderComponent/${lenderId}`,
                { data: newData })
            return response;
        },
        {
            onSuccess: (data, variables, context) => {
                let newAuctionItemId = data.data;
                toast.success("Your offer was deleted.");
                queryClient.invalidateQueries([queryKeys["Dashboard"], lenderId]);
                queryClient.invalidateQueries([queryKeys["Lender"], auctionId]);

                setNavigateUrl(lenderId);
                navigate("/UserDashboard", { state: { "auctionEntityId": navigateUrl } });
            },
            onError: (error, variables, context) => {
                if (error && error.response && error.response.data)
                    toast.error('Your action failed due to: ' + JSON.stringify(error.response.data));
                else {
                    alert('Delete error was ' + error);
                    toast.error('Got an unknown error on the transactions =' + error);
                }
            },
        }

    );



    const enterPoolViaApi = () => {
        var jsonData = {};
        jsonData["AuctionId"] = data.lenderComponent.auctionId;
        jsonData["AuctionEntityId"] = lenderId;
        jsonData["AuctionItemId"] = auctionItemId;

        jsonData["amount"] = loanAmount;
        jsonData["percentage"] = loanPercentage;
        jsonData["timeSpan"] = loanTimeSpan;
        enterPoolApi.mutate(jsonData);
        return null;
    }


    const updateLenderViaApi = (name, value) => {
        let result = updateInfo(name, value);
        var jsonData = {};
        jsonData["AuctionId"] = data.lenderComponent.auctionId;
        jsonData["AuctionEntityId"] = lenderId;
        jsonData["AuctionItemId"] = data.lenderComponent.auctionItemId;
        jsonData["amount"] = result.loanAmount;
        jsonData["percentage"] = result.loanPercentage;
        jsonData["timeSpan"] = result.loanTimeSpan;
        updateLenderApi.mutate(jsonData);
        return null;
    }

    function compareToNationalAverage() {
        let result = loanTimeSpan * calculateMontlyPayment(loanAmount, loanTimeSpan, loanPercentage - vig - nationalAverageROR) - loanAmount;
        if (result > 0)
            return result;
        else
            return 0;
    }

    const exitPoolViaApi = () => {
        var jsonData = {};
        jsonData["AuctionId"] = data.lenderComponent.auctionId;
        jsonData["AuctionEntityId"] = lenderId;
        jsonData["AuctionItemId"] = auctionItemId;
        setAuctionId(null);

        exitPoolApi.mutate(jsonData);
        return null;
    }


    function doDelete() {
        exitPoolViaApi();
    }

    function doCommit() {
        if (!lenderId || lenderId == emptyGuid) {
            toast.error("You must be logged in to commit.");
            return;
        }
        enterPoolViaApi();
    }

    if (isFetching || !lenderId || status === "loading") return <div><CircularProgress /></div>;
    if (error)
        return "An error has occurred: " + error.message;

    let testData = DataIsValid();
    if (testData != 0)
        return (<div><CircularProgress size={20} /> &nbsp; Validating step {testData}</div>);


    return (
        <>
            <div className="bigPadding">
                <div><Toaster /></div>
                <h5>Lender Status For: {data.lenderComponent.yourName}  </h5>
                <Grid container spacing={1} sx={{ width: 1000 }} columns={1} direction="row">
                    <Grid item>
                        <Box sx={{ width: 1000 }} className="shimmer bigPadding">
                            <Grid item>
                                <Box sx={{ width: 1000 }}>
                                    <Grid container spacing={5} columns={10}>
                                        <Grid item xs={4} >
                                            <ContinuousSlider
                                                minValue={data.lenderComponent.yourLoanAmountMinimum}
                                                maxValue={data.lenderComponent.yourLoanAmountMaximum}
                                                value={loanAmount}
                                                title='Loan Amount'
                                                stepSize={1000}
                                                formatStyle='$$'
                                                notifyParent={updateLenderViaApi}
                                                notifyParentName='amount'
                                                keyx={loanAmount}
                                            />
                                        </Grid>
                                        <Grid item xs={1} ><Vl /></Grid>

                                        <Grid item xs={4} >
                                            <ContinuousSlider
                                                minValue={data.lenderComponent.yourLoanPercentageMinimum}
                                                maxValue={data.lenderComponent.yourLoanPercentageMaximum}
                                                value={loanPercentage}
                                                title='Loan Percent'
                                                stepSize={0.005}
                                                formatStyle='%'
                                                formatLabels={true}
                                                notifyParent={updateLenderViaApi}
                                                notifyParentName='percentage'
                                                keyx={loanPercentage}
                                            />
                                        </Grid>


                                    </Grid>
                                </Box>
                                <Box >
                                    <Grid container spacing={1} columns={2} direction="row" sx={{ width: 900 }} >
                                        <Grid item sx={{ width: 900 }}>
                                            <SectionHeaderComponent line1='Your Numbers' />
                                        </Grid>

                                    </Grid>
                                </Box>
                                <Grid container spacing={1} columns={2} direction="row">
                                    <Box sx={{ width: 950 }}>

                                        <Grid container spacing={0} columns={16}>

                                            <Grid item xs={3} >
                                                <BigSmallTextComponent
                                                    line1={FormatValue('%', loanPercentage)}
                                                    line2='Minimum Interest Rate'
                                                    key={loanPercentage} />
                                            </Grid>

                                            <Grid item xs={1} ><Vl /></Grid>

                                            <Grid item xs={3}  >

                                                <BigSmallTextComponent
                                                    line1={FormatValue('$', calculateMontlyPayment(loanAmount, loanTimeSpan, loanPercentage - vig))}
                                                    line2='Maximum Monthly Return'
                                                    key={loanAmount + '|' + loanPercentage} />
                                            </Grid>

                                            <Grid item xs={1}   ><Vl /></Grid>

                                            <Grid item xs={3} >
                                                <BigSmallTextComponent
                                                    line1={FormatValue('$', loanTimeSpan * calculateMontlyPayment(loanAmount, loanTimeSpan, loanPercentage - vig))}
                                                    line2='Maximum REPAYMENT AMOUNT'
                                                    key={loanAmount + '|' + loanPercentage} />
                                            </Grid>

                                            <Grid item xs={1}  ><Vl /></Grid>

                                            <Grid item xs={3} >
                                                <BigSmallTextComponent
                                                    line1={FormatValue('$$', compareToNationalAverage())}
                                                    line2='RETURN COMPARED TO NATIONAL AVERAGE'
                                                    key={loanAmount + '|' + loanPercentage} />
                                            </Grid>

                                        </Grid>

                                        <Stack spacing={2} direction="row">
                                            <Box sx={{ width: 600 }}>
                                                <CurrentAuctionChartComponent
                                                    auctionId={auctionId}
                                                    yourPercentage={loanPercentage}
                                                    requiredToClose={data.lenderComponent.requiredToClose}
                                                    key={loanAmount + '|' + loanPercentage}
                                                />
                                            </Box>

                                            <Box sx={{ width: 250 }}>
                                                {!auctionItemId &&
                                                    <Button variant="contained" color="success" onClick={doCommit}>
                                                        Commit
                                                    </Button>
                                                }
                                                {auctionItemId &&
                                                    <Button variant="contained" color="success" onClick={doDelete}>
                                                        Remove My Offer
                                                    </Button>
                                                }
                                            </Box>

                                        </Stack>

                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}

export default LenderComponent;
