import React from "react";
import { useForm } from "react-hook-form";
import AuthService from "../services/auth.service";
import { aesEncrypt } from "../services/aes2";
import { alpha, Box, Button, Card, Grid, IconButton, TextField, styled, Switch } from "@mui/material";
import {Link } from 'react-router-dom';
import getHttpResponseError from "./GetHttpResponseError";
import "../css/Login.css";

import { useLocation, useNavigate, useParams } from "react-router-dom";

export default function Login(props) {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [loading, setLoading] = React.useState('');
    const [message, setMessage] = React.useState('');

    let navigate = useNavigate();


    function onSubmit(data) {
        //e.preventDefault();

        setLoading(true);
        AuthService.login(data.userName, aesEncrypt(data.password)).then(
            () => {
                navigate("/");
                window.location.reload();
            },
            error => {
                const resMessage = getHttpResponseError(error);
                setMessage(resMessage);
                setLoading(false);
            }
        );
    }

    return (

        <div className="col-md-12">
            <div className="card card-container">
                <img
                    src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                    alt="profile-img"
                    className="profile-img-card"
                />

                <form onSubmit={handleSubmit(onSubmit)}>

                    <div>&nbsp;</div>
                    <div className="form-group">
                        <TextField
                            fullWidth
                            style={{
                                backgroundColor: "white"
                            }}
                            name="userName"
                            label="User Name"
                            {...register("userName", { required: "User Name is required" })}
                            aria-invalid={errors.userName ? "true" : "false"}
                        />
                        {errors.userName && <div role="alert" className="alert alert-danger">{errors.userName?.message}</div>}
                    </div>
                    <div>&nbsp;</div>

                    <div className="form-group">
                        <TextField
                            fullWidth
                            style={{
                                backgroundColor: "white"
                            }}
                            name="password"
                            label="Password"
                            type="password"

                            {...register("password", { required: "Password is required" })}
                            aria-invalid={errors.password ? "true" : "false"}

                        />
                        {errors.password && <div className="alert alert-danger" role="alert">{errors.password?.message}</div>}
                    </div>
                    <div className="form-group">
                        <Button variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            fullWidth
                            type="submit"
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>Login</span>
                        </Button>
                    </div>

                    {message && (
                        <div className="form-group">
                            <div
                                className={"alert alert-danger"}
                                role="alert"
                            >
                                {message}
                            </div>
                        </div>
                    )}
                </form>
            </div>
        </div>

    );
}

