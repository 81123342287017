/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useParams} from 'react-router-dom'
import AuctionViewComponent from "./AuctionViewComponent";
import { useLocation } from 'react-router-dom';
import { CircularProgress } from "@mui/material";
import "../css/ShimmerBox.css";


function AuctionViewComponentRoot() {
    const handle = useParams();
    const [auctionId, setAuctionId] =  React.useState(handle.auctionId);
    const { state } = useLocation();

    useEffect(() => {
        if (state) {
            if (state.auctionId) setAuctionId(state.auctionId);
        }
    }, []);

    if (!auctionId) return <div><CircularProgress  /> &nbsp; Waiting for AuctionId</div> ;

    return (
        <>
        <div>
            <AuctionViewComponent auctionId={auctionId} />
        </div>
        <div>
            &nbsp;
        </div>
        <div>
            &nbsp;
        </div>

        </>

    );
}

export default AuctionViewComponentRoot;
