export function QueryKeys() {
    return (
        {
            "Borrower": "Borrower",
            "Lender": "Lender",
            "Dashboard": "Dashboard",
            "AuctionList": "AuctionList",
            "UserProfile": "UserProfile",
            "AuctionChart": "AuctionChart",
            "AuctionView": "AuctionView",
            "AuctionList": "AuctionList",
            "AuctionItems": "AuctionItems"
        }
    );
}
