/*
    Defines the root of the application 
*/

const node_env = process.env["NODE_ENV"];  // 
const urlOffset = '/apiBase';  //string used by nginx to do proxy
const targetPort = ':5126'

export function GetUrlBaseV2() {     
    var base_url = window.location.origin;
    var targetAddress = "http://localhost" + targetPort;
    if(node_env == "production"){
        targetAddress =  base_url + urlOffset;
    }
    return targetAddress;
}
  