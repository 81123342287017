import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from "axios";
import { useQuery } from "react-query";
import { useQueryClient } from "react-query";
import { useParams } from 'react-router-dom'
import DataTable from './DataTable';
import { InitDataTable } from './DataTable';
import { AddDataTableField, AddLinkTableField } from './DataTable';
import { FormatValue } from './FormattingUtilities';
import "../css/LendingComponent.css";
import "../css/UserList.css";
import "../css/ShimmerBox.css";
import { GetUrlBaseV2 } from "./GetUrlBaseV2";
import { CircularProgress } from '@mui/material';
import { useTitle } from './UseTitle';
import { useLocation } from 'react-router-dom';
import AuthService from "../services/auth.service";
import { GetCurrentTime } from "./FormattingUtilities";
import { QueryKeys } from "./QueryKeys";
import toast, { Toaster } from 'react-hot-toast';



function UserDashboard(props) {
    const handle = useParams();
    const { state } = useLocation();
    const [auctionEntityId, setAuctionEntityId] = React.useState(AuthService.getAuctionEntityId());
    const [testData] = useState(props.testData)

    const queryClient = useQueryClient();
    const urlBase = GetUrlBaseV2();
    const queryKeys = QueryKeys();

    const [intervalMs/*, setIntervalMs*/] = React.useState(10000);
    const { status, data, error , isFetching } = useEntity(auctionEntityId, testData);

    const getDashboardByEntityId = async (id) => {
        let dashboardData = '';
        const output = await axios.get(
            `${urlBase}/api/UserDashboard/${id}`
        ).then(res => {
            dashboardData = res.data;
        }).catch(function (error) {
            toast.error('An error was returned. Check log');
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
        return dashboardData;
    };

    function useEntity(auctionEntityId, testData) {
        return useQuery([queryKeys["Dashboard"], auctionEntityId], () => getDashboardByEntityId(auctionEntityId), {
            enabled: !!auctionEntityId && !testData,
        }
            ,
            {
                // Refetch the data every minute
                refetchInterval: intervalMs,
            },

        );
    }

    const actualData = !testData ? data : testData;
    if (!auctionEntityId) {
        return <div>NO Entity Information</div>
    }

    if (isFetching || status === "loading" || !actualData || !actualData.borrows) return <div><CircularProgress /></div>;
    if (error) return "An error has occurred: " + error.message;


    return (
        <div className="bigPadding">
            <div><Toaster /></div>
            <div className="title1">User Dashboard for {actualData.userName}</div>
            <div>&nbsp;</div>

            <div className="subtitle1">Open Borrows</div>
            <div>&nbsp;</div>
            {

                (actualData.borrows.length == 0) ?
                    'None'
                    :
                    rowList(1, actualData.borrows)

            }
            <div>&nbsp;</div>

            <div className="subtitle1">Open Lends </div>
            <div>&nbsp;</div>

            {
                (actualData.lends.length == 0) ?
                    'None'
                    :
                    rowList(2, actualData.lends)
            }
            <div>&nbsp;</div>

            <div className="subtitle1">Banking Information</div>
            <div>&nbsp;</div>

            {
                actualData.bankingInfo
            }
            <div>&nbsp;</div>

            <div className="subtitle1">Closed Borrows (loans still being paid by you)</div>
            <div>&nbsp;</div>

            {
                actualData.closedBorrows.length == 0 ? 'None' :
                    JSON.stringify(actualData.closedBorrows)

            }
            <div>&nbsp;</div>

            <div className="subtitle1">Closed Lends (loans still being paid to you)</div>
            <div>&nbsp;</div>

            {
                actualData.closedLends.length == 0 ? 'None' :
                    JSON.stringify(actualData.closedLends)

            }
        </div>
    );
} /* end render()*/



function MkBorrowerOrLenderLink(row) {
    const auctionEntityId = row.auctionEntityId;
    const auctionItemId = row.auctionItemId;
    const auctionId = row.auctionId;
    //const result = `${auctionEntityId}/${auctionId}/${auctionItemId}`;
    let result = { "auctionEntityId": auctionEntityId, "auctionId": auctionId, "auctionItemId": auctionItemId };
    result = JSON.stringify(result);
    return result;
}

function prepareDataTable(auctionItemTypeId) {
    let baseColumns = InitDataTable();
    const linkToAddress = auctionItemTypeId == 1 ? 'borrower' : 'lender';
    baseColumns = AddLinkTableField(baseColumns, 'id', 'Action', 120, '', false, linkToAddress);
    baseColumns = AddDataTableField(baseColumns, 'range', 'Range', 120, '', true, '');
    baseColumns = AddDataTableField(baseColumns, 'status', 'In Pool', 120, 'number', true, '');
    baseColumns = AddDataTableField(baseColumns, 'amount', 'Amount', 120, 'number', true, '');
    baseColumns = AddDataTableField(baseColumns, 'percentage', 'Percentage', 130, 'number', true, '');
    baseColumns = AddDataTableField(baseColumns, 'originDc', 'Source', 200, '', true, '');
    return baseColumns;
}

function formatRows(rows) {
    let result = rows.map(row => ({
        ...row,
        id: MkBorrowerOrLenderLink(row),
        percentage: FormatValue('%', row.currentPercentage),
        amount: FormatValue('$$', row.amount),
        status: row.InPool != 0 ? 'Yes' : 'No',
        range: '(' + row.minCreditScore + '-' + row.maxCreditScore + ')',
        currentTimeSpan: row.currentTimeSpan + ' Months'
    }));
    return result;
}


function rowList(auctionItemTypeId, inputRows) {
    //if (inputRows == null || inputRows.rows == null)
    //    return ('Empty');
    let columns = prepareDataTable(auctionItemTypeId);
    if (auctionItemTypeId == 1)
        columns = AddDataTableField(columns, 'currentTimeSpan', 'Time Span', 90, '', true, '');
    let rows = formatRows(inputRows);
    return (
        <DataTable rows={rows} columns={columns} />
    );
}


export default UserDashboard;

