import React, { useState } from "react";
import { AreaChart, Area, YAxis, XAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, ReferenceArea } from 'recharts';
import { GetUrlBaseV2 } from "./GetUrlBaseV2";
import { QueryKeys } from "./QueryKeys";
import axios from "axios";
import { useQueryClient, useQuery } from "react-query";
import { CircularProgress } from "@mui/material";
import { FormatMoneyRounded, FormatPct } from "./FormattingUtilities";
import "../css/tooltip.css";

import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material';
import "../css/Poppins.css";
import "../css/Chilanka.css";
import "../css/Audiowide.css";



const urlBase = GetUrlBaseV2();
const queryKeys = QueryKeys();

const CustomizedDot = (props) => {
    const { cx, cy, stroke, payload, value } = props;
    return (<empty />);
};


function CurrentAuctionChartComponent(props) {
    const [auctionId] = useState(props.auctionId);
    const [yourPercentage] = useState(props.yourPercentage);
    const [requiredToClose] = useState(props.requiredToClose);
    const [testData] = useState(props.testData)
    const [intervalMs/*, setIntervalMs*/] = React.useState(10000);




    const useFont = 'Poppins';

    const queryClient = useQueryClient();
    const { status, data, error/*, isFetching*/ } = useChart(auctionId, testData);

    const getChartItemById = async (auctionId) => {
        const { data } = await axios.get(
            `${urlBase}/api/ChartItem?auctionId=${auctionId}`
        );
        return data;
    };

    function useChart(auctionId, testData) {
        return useQuery([queryKeys["AuctionChart"], auctionId], () => getChartItemById(auctionId), {
            enabled: !!auctionId && !testData,
        }
            ,
            {
                // Refetch the data every minute
                refetchInterval: intervalMs,
            },

        );
    }

    function myInterceptor(x) {
        if (x && x.payload) {
            return tooltipFormatter(null, x.payload, null);
        }
    }

    function tooltipFormatter(active, payload, label) {
        try {
            if (payload && payload[0] && payload[0].payload.amount) {
                let result = "Available: "
                    + FormatMoneyRounded(payload[0].payload.amount)
                    + " @ "
                    + FormatPct(payload[0].payload.percentage);

                return (
                    <div className="chartTip"><pre>
                        {result}
                    </pre></div>

                );
            } else
                return '';
        } catch (e) {
            console.log(e);
            return 'ERROR';
        }
    }

    const hostData = !testData ? data : testData;


    if (!hostData || status === "loading") return <div><CircularProgress /></div>;
    //if (isFetching) return "Background Fetch...";
    if (error) return "An error has occurred: " + error.message;


    const percentagePlaceHolder = !yourPercentage ? 0 : yourPercentage;
    let minPercentage = 0.03;
    let maxPercentage = 0.55;

    const displayData = hostData.chartData;

    try {
        if (hostData && displayData) {
            minPercentage = Math.min(...displayData.map(entry => entry.percentage));
            maxPercentage = Math.max(...displayData.map(entry => entry.percentage));
            if (percentagePlaceHolder > 0) {
                maxPercentage = Math.max(maxPercentage, percentagePlaceHolder);
                minPercentage = Math.min(minPercentage, percentagePlaceHolder);
            }
        }
        else {
            console.log('ERR -> could not set limit -> displayData = ' + JSON.stringify(displayData));

        }
    } catch (e) {
        console.error('CAE1: ' + e);
    }
    //statusReport();

    minPercentage = RoundDownEven(minPercentage);
    maxPercentage = RoundUpEven(maxPercentage);
   
    function RoundUpEven(value)
    {
        let num = value * 100;
        let result = Math.trunc(num + 1.5) ;
        result = Math.floor(result - result % 2);
        return result / 100;
    }


    function RoundDownEven(value)
    {
        let num = value * 100;
        let result = Math.floor(num - num % 2);
        return result / 100;
    }




    let maxLends = -1;
    let maxBorrows = -1;
    let maxInputs = -1;
    try {
        if (hostData && displayData) {
            maxBorrows = Math.max(...displayData.map(entry => entry.borrows));
            maxLends = Math.max(...displayData.map(entry => entry.lends));
            maxInputs = Math.max(maxBorrows, maxLends);
        }
        else {
            console.log('CAE2 -> could not set limit -> displayData = ' + JSON.stringify(displayData));

        }
    } catch (e) {
        console.error('CAE1: ' + e);
    }
 
    function RoundIt(value)
    {
        let e = parseInt(Math.log10(value));
        let f = parseInt(Math.pow(10, e));
        let g = (parseInt(f) + parseInt(value)) / parseInt(f);
        let result = parseInt(g) * parseInt(f);
        return result;
    }


    const matchesPosition = hostData.resultSummary.percentage + (maxPercentage - minPercentage) / 10.0;

    //const chartTop = requiredToClose * 1.2;
    const chartTop = RoundIt(Math.max( maxInputs, requiredToClose * 1.2 ));
    const roundedPercentage = Math.round(1000 * percentagePlaceHolder) / 1000.0;
    
    const scale = 1;
    const width_config = 700 / scale;
    const height_config = 400 / scale;
    const margin_config = { top: 100 / scale, right: 30 / scale, left: 100 / scale, bottom: 20 / scale };
    const _OneEm = (1 / scale) + 'em';
    const _Point75Em = (0.75 / scale) + 'em';
    const _Point50Em = (0.50 / scale) + 'em';
    const verticalUnit = chartTop * (16/(scale)) / height_config ;

    try {
        return (
            <>
                <AreaChart width={width_config} height={height_config} data={displayData}
                    margin={margin_config}>


                    <Area type="monotone" dataKey="borrows" stroke="#eff6fd" fillOpacity={0} fill="#ffffff" strokeWidth={4} />
                    <Area type="monotone" dataKey="lends" stroke="#f8faec" fillOpacity={0} fill="#ffffff" strokeWidth={4} />
                    <Area type="monotone" dataKey="intersection" stroke="#aac1e8" fillOpacity={0.95} fill="#f3faff" strokeWidth={4} />

                    <XAxis
                        type="number"
                        domain={[minPercentage, maxPercentage]}
                        tickFormatter={tick => {
                            return Math.round(tick * 1000) / 10 + '%    '
                        }}
                        dataKey="percentage"
                        fontFamily={useFont}
                        fontSize={_OneEm}

                    />
                    <YAxis
                        domain={[0, chartTop]}
                        tickFormatter={tick => {
                            return '$' + tick.toLocaleString();
                        }}
                        fontFamily={useFont}
                        fontSize={_OneEm}
                    />


                    <ReferenceLine
                        x={hostData.resultSummary.percentage}
                        className={useFont}
                        stroke="#0078c1"

                        label={{
                            value: 'Estimated Rate', angle: 0, position: 'top'
                            , offset: 10, fill: '#0078c1', fontSize: _OneEm, fontWeight: 'bold'
                        }}
                        strokeWidth={4}
                    />

                    <ReferenceLine x={roundedPercentage}
                        className={useFont}
                        stroke="#d8e8a9"
                        label={{ value: 'you', angle: 0, position: 'top', offset: 0, fontSize: _Point50Em, fontWeight: 'bold' }}
                        strokeWidth={5} />

                    <ReferenceLine y={requiredToClose}
                        stroke="#fbb48b" strokeDasharray="3 3" width={200} />

                    <ReferenceLine y={(parseFloat(requiredToClose) + 1.25 * parseFloat(verticalUnit))}
                        className={useFont}
                        label={{
                            value: "REQUIRED TO CLOSE", fontSize: _Point75Em
                            , fill: '#ea440e', position: 'right', offset: -100 / scale, fontWeight: 'bold'
                        }}
                        stroke="none"
                    />
                    <ReferenceLine y={(parseFloat(requiredToClose) + 2.75 * parseFloat(verticalUnit))}
                        className={useFont}
                        label={{
                            value: FormatMoneyRounded(requiredToClose), fontSize: _OneEm
                            , fill: '#ea440e', position: 'right', offset: -75 / scale, fontWeight: 'bold'
                        }}
                        stroke="none"
                    />


                    <ReferenceLine x={matchesPosition}
                        className={useFont}
                        label={{
                            value: "In Pool", fontSize: _OneEm, fill: '#b3b2b4',
                            position: 'bottom', offset: -20 / scale, fontWeight: 'bold'
                        }}
                        stroke="none"
                    />





                </AreaChart>

            </>
        );

    } catch (e) {
        return ('CACC02: Chart could not display due to ' + e);
    }

}

export default CurrentAuctionChartComponent;