import React, { Component } from 'react';
import { useState, setState } from "react";
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from 'react-query/devtools'
import Home from './components/home';
import About from './components/about';
import Contact from './components/contact';
import BorrowerComponent from './components/BorrowerComponent';
import AuctionViewComponentRoot from './components/AuctionViewComponentRoot';
import LenderComponent from './components/LenderComponent';
import UserList from './components/UserList';
import AuctionList from './components/AuctionList';
import UserAuctionList from './components/UserAuctionList';
import AuctionItems from './components/AuctionItems';
import UserDashboard from './components/UserDashboard';
import Login from './components/LoginComponent';
import UserProfile from './components/UserProfile';
import Register from './components/RegisterComponent';
import Gravatar from 'react-gravatar';
import AuthService from "./services/auth.service";
import ResetPassword from "./components/ResetPassword";
import TopLeftLogo from './images/LendingPoolLogo.png';
import './App.css';
import "./css/Poppins.css";



const queryClient = new QueryClient();

function logOut() {

    AuthService.logout();
}


export default function App() {

    const user = AuthService.getCurrentUser();
    const [currentUser] = useState(user);
    const auctionEntityId = AuthService.getAuctionEntityId();
    const emptyGuid = "00000000-0000-0000-0000-000000000000";

    
    return (

        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <Router>
                <div>
                    <div className="header">
                        <Link className="logo" to="/">
                            <img src={TopLeftLogo} />
                        </Link>
                        <div className="header-right">
                            <Link to="/">Home</Link>
                            <Link to="/about" >About Us</Link>
                            <Link to="/contact">Contact Us</Link>
                            {AuthService.roleIsAdmin() &&
                                <Link to="/users">User List</Link>
                            }
                            {AuthService.roleIsAdmin() &&
                                <Link to="/auctions">Auction List</Link>
                            }
                            {!AuthService.roleIsAdmin() &&
                                <Link to="/userauctions">Auction List</Link>
                            }
                            {auctionEntityId != emptyGuid &&
                                <Link to="/UserDashboard" state={{ "auctionEntityId": auctionEntityId }} >   User Dashboard</Link>
                            }
                            {!!currentUser ? (
                                <>
                                    <a href="/" onClick={logOut}>
                                        Log Out
                                    </a>
                                    <Link className="nopad" to="/userProfile">
                                        <Gravatar className="gvwrap" email={currentUser.email} default="mp" size={48} />
                                    </Link>

                                </>
                            ) : (
                                <>
                                    <Link to="/signup">Sign Up</Link>
                                    <Link to="/login">Login</Link>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                                
                </div>
                <Routes>
                    <Route exact path='/about' element={< About />}></Route>
                    <Route exact path='/contact' element={< Contact />}></Route>
                    <Route exact path='/borrower' element={< BorrowerComponent />}></Route>
                    <Route exact path='/lender' element={< LenderComponent />}></Route>
                    <Route exact path='/users' element={< UserList />}></Route>
                    <Route exact path='/auctions' element={< AuctionList />}></Route>
                    <Route exact path='/reset' element={< ResetPassword />}></Route>
                    <Route exact path='/userauctions' element={< UserAuctionList />}></Route>
                    <Route exact path='/AuctionItems/:auctionId' element={< AuctionItems />}></Route>
                    <Route exact path='/AuctionViewComponentRoot' element={< AuctionViewComponentRoot />}></Route>
                    <Route exact path='/UserDashboard' element={< UserDashboard />}></Route>
                    <Route exact path='/login' element={< Login />}></Route>
                    <Route exact path="/" element={<Home />} />
                    <Route exact path='/signup' element={< Register />}></Route>
                    <Route exact path='/userProfile' element={< UserProfile />}></Route>
                    <Route path='*' element={<Navigate replace to="/about" />} />
                </Routes>
            </Router>
        </QueryClientProvider >

    );
}