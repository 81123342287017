// src/components/line.rechart.js
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React from "react";
import { AreaChart, Area, YAxis, XAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, ReferenceArea } from 'recharts';
import "../css/tooltip.css";
import "../index.css";

import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material';
import "../css/Poppins.css";
import "../css/Chilanka.css";
import "../css/Audiowide.css";


function AreaChartComponent() {

    const useFont = 'Poppins';



    const data = [
        {
            "percentage": 0.10,
            "borrows": 50000,
            "lends": 0,
            "intersection": 0
        },
        {
            "percentage": 0.11,
            "borrows": 70000,
            "lends": 0,
            "intersection": 0
        },
        {
            "percentage": 0.12,
            "borrows": 70000,
            "lends": 10000,
            "intersection": 10000
        },
        {
            "percentage": 0.13,
            "borrows": 60000,
            "lends": 100000,
            "intersection": 60000
        },
        {
            "percentage": 0.14,
            "borrows": 2000,
            "lends": 50000,
            "intersection": 2000
        },
        {
            "percentage": 0.15,
            "borrows": 2000,
            "lends": 50000,
            "intersection": 2000
        },
        {
            "percentage": 0.16,
            "borrows": 0,
            "lends": 50000,
            "intersection": 0
        }
    ]




    return (
        <>

            <Grid container spacing={1} sx={{ width: 800 }} columns={2} direction="row">
                <Grid item>
                    <Box sx={{ width: 800 }} className="shimmer bigPadding">

                        <AreaChart width={730} height={500} data={data}
                            margin={{ top: 100, right: 30, left: 100, bottom: 20 }}>

                            <Area type="monotone" dataKey="borrows" stroke="#eff6fd" fillOpacity={0} fill="#ffffff" strokeWidth={4} />
                            <Area type="monotone" dataKey="lends" stroke="#f8faec" fillOpacity={0} fill="#ffffff" strokeWidth={4} />
                            <Area type="monotone" dataKey="intersection" stroke="#aac1e8" fillOpacity={0.95} fill="#f3faff" strokeWidth={4} />


                            <ReferenceLine y={20000}
                                className={useFont}
                                label={{
                                    value: "Borrowers", fontSize: '1em', fill: '#27abe6',
                                    position: 'left', offset: -150, fontWeight: 'bold'
                                }}
                                stroke="none" />

                            <ReferenceLine y={10000}
                                className={useFont}
                                label={{
                                    value: "Matches", fontSize: '1em', fill: '#b3b2b4',
                                    position: 'left', offset: -250, fontWeight: 'bold'
                                }}
                                stroke="none" />


                            <ReferenceLine y={25000}
                                className={useFont}
                                label={{
                                    value: "Lenders", fontSize: '1em', fill: '#cad400',
                                    position: 'left', offset: -450, fontWeight: 'bold'
                                }}
                                stroke="none" />


                            <XAxis dataKey="percentage"
                                tickFormatter={tick => {
                                    return Math.round(tick * 1000) / 10 + '%    '
                                }}
                                fontFamily={useFont}



                            />
                            <YAxis
                                fontFamily={useFont}
                                tickFormatter={tick => {
                                    return '$' + tick.toLocaleString();
                                }}

                            />
                            <Tooltip />
                            <ReferenceLine
                                x={".13"}
                                className={useFont}
                                stroke="#0078c1"
                                label={{
                                    value: 'Estimated Rate', angle: 0, position: 'top'
                                    , offset: 10, fill: '#0078c1', fontSize: '1em', fontWeight: 'bold'
                                }}
                                strokeWidth={4}

                            />
                            <ReferenceLine y={80000}
                                stroke="#fbb48b" strokeDasharray="3 3" width={'10%'} />

                            <ReferenceLine y={80000}
                                className={useFont}
                                label={{
                                    value: "REQUIRED TO CLOSE (V3)", fontSize: '.75em'
                                    , fill: '#ea440e', position: 'right', offset: -120, fontWeight: 'bold'
                                }}
                                stroke="none" offset={100}

                            />



                        </AreaChart>


                    </Box>
                </Grid>
            </Grid>
        </>
    );

}

export default AreaChartComponent;