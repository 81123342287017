import React from 'react';
import part1 from '../images/HomeTop.png';
import part2 from '../images/HomeMiddle.png';
import part3 from '../images/HomeBottom.png';

function Home (){
	return <div>
     <img src={part1}  />
     <img src={part2}  />
     <img src={part3}  />

    </div>

}

export default Home;



