/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import axios from "axios";
import { useQuery } from "react-query";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import BigSmallTextComponent from './BigSmallTextComponent';
import Vl from './Vl';
import { FormatValue } from "./FormattingUtilities.js";
import { Link } from 'react-router-dom';
import CurrentAuctionChartComponent from "./CurrentAuctionChartComponent"
import SectionHeaderComponent from "./SectionHeaderComponent";
import "../css/ShimmerBox.css";
import { useQueryClient } from "react-query";
import { GetUrlBaseV2 } from "./GetUrlBaseV2";
import { QueryKeys } from "./QueryKeys";
import { CircularProgress } from "@mui/material";
import AuthService from "../services/auth.service";
import {Button, Stack } from '@mui/material';

const urlBase = GetUrlBaseV2();
const queryKeys = QueryKeys();



function AuctionViewComponent(props) {
    const [auctionId] = useState(props.auctionId);
    let auctionEntityId = AuthService.getAuctionEntityId();
    const queryClient = useQueryClient();
    const { status, data, error /*, isFetching*/ } = useAuction(auctionId);
    const getAuctionById = async (auctionId) => {
        const { data } = await axios.get(
            `${urlBase}/api/ChartItem?auctionId=${auctionId}`
        );

        return data;
    };

    function useAuction(auctionId) {
        const [intervalMs/*, setIntervalMs*/] = React.useState(10000);
        return useQuery([queryKeys["AuctionView"], auctionId], () => getAuctionById(auctionId), {
            enabled: !!auctionId,
        }
            ,
            {
                // Refetch the data every minute
                refetchInterval: intervalMs,
            },

        );
    }


    function statusMessages(auctionId, status, error, isFetching) {
        if (error) return "An error has occurred: " + error.message;
        var d = new Date();
        var datetime = d.toLocaleString();
        if (isFetching)
            console.log(datetime + " Background Fetch...");
        return "";
    }

     function statusReport() {
        
    }

    function formatPercentage(value){
        if(value > 1 || value < 0)
            return 'None';
        return  FormatValue('%', value);
    }

    function DataIsValid() {
        if (data == null) {
            console.log('DataIsValid: data is null');
            return false;
        }
        if (data.resultSummary == null) {
            console.log('DataIsValid: resultSummary is null');
            return false;
        }
        return true;
    }



    if (!auctionId || status === "loading") return <div><CircularProgress  /></div> ;
    /*if (isFetching) return "Background Fetch...";*/
    if (error) return "An error has occurred: " + error.message;

    if (!DataIsValid())
        return  (<div><CircularProgress size={20} /> &nbsp; Validating </div>);


    return (

        <div className="bigPadding">
            {statusReport()}
            <Grid container spacing={1} sx={{ width: 800 }} columns={2} direction="row">
                <Grid item>
                    <Box sx={{ width: 800 }} className="shimmer bigPadding">
                        <Grid item>                            
                            <Box >
                                <Grid container spacing={1} columns={3} direction="row" sx={{ width: 1100 }} >
                                    <Grid item sx={{ width: 500 }}>
                                        <SectionHeaderComponent line1='Auction Numbers' />
                                    </Grid>
                                    <Grid item sx={{ width: 200 }}>
                                    <Stack spacing={2} direction="row">
                                    <Link to="/borrower" 
                                            state={{"auctionEntityId": auctionEntityId, "auctionId": auctionId}}>                                    
                                        <Button variant="contained"> 
                                            Borrow
                                        </Button>
                                    </Link>
                                    <Link to="/lender" 
                                            state={{"auctionEntityId": auctionEntityId, "auctionId": auctionId}}>                                    
                                        <Button variant="contained">
                                            Lend
                                        </Button>
                                    </Link>
                                    </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Grid container spacing={1} columns={2} direction="row">
                                <Box sx={{ width: 800 }}>
                                    <Grid container spacing={0} columns={16}>
                                        <Grid item xs={3} >
                                            <BigSmallTextComponent
                                                line1={ formatPercentage(data.resultSummary.percentage)}
                                                line2='Interest Rate'
                                                key={data.auctionId} />
                                        </Grid>

                                        <Grid item xs={1} ><Vl /></Grid>

                                        <Grid item xs={3}  >

                                            <BigSmallTextComponent
                                                line1={FormatValue('$', data.resultSummary.amount)}
                                                line2='Committed Amount'
                                                key={data.auctionId} />
                                        </Grid>

                                        <Grid item xs={1}   ><Vl /></Grid>

                                        <Grid item xs={3} >
                                            <BigSmallTextComponent
                                                line1={!!data.resultSummary.count?data.resultSummary.count:'None'}
                                                line2='# Of Participants'
                                                key={data.auctionId} />
                                        </Grid>

                                    </Grid>
                                    <Box sx={{ width: 600 }}>
                                        <CurrentAuctionChartComponent 
                                             auctionId={auctionId} 
                                             yourPercentage={-1}
                                             requiredToClose={data.requiredToClose}
                                             key={data.resultSummary.amount}
                                        />
                                    </Box>

                                </Box>

                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>


        </div>
    );
}

export default AuctionViewComponent;

