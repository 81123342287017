import React from "react";
import "../css/vl.css";

class Vl extends React.Component {
    constructor(props) {
        super();
    }


    render() {


        return (
            <div className="vl">
            </div>
        );
    } /* end render()*/

    

}


export default Vl;

