import React, { Component, setState } from "react";
import { useForm } from "react-hook-form";
import { aesEncrypt } from "../services/aes2";
import AuthService from "../services/auth.service";
import { alpha, Box, Button, Card, Grid, IconButton, TextField, styled, Switch } from "@mui/material";
import getHttpResponseError from "./GetHttpResponseError";
import "../css/Login.css";


export default function Register() {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [registerComplete, setRegisterComplete] = React.useState(false);
    const [message, setMessage] = React.useState('');

    function onSubmit(data) {
        AuthService.register(
            data.userName,
            data.email,
            aesEncrypt(data.password)
        ).then(
            response => {
                setMessage(response.data.message);
                setRegisterComplete(true);
            }
            ,
            error => {
                const resMessage = getHttpResponseError(error);

                setMessage(resMessage);
                setRegisterComplete(false);
            }
        );
    }

    return (
        <div className="col-md-12">
            <div className="card card-container">
                <img
                    src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                    alt="profile-img"
                    className="profile-img-card"
                />

                <form onSubmit={handleSubmit(onSubmit)}>
                    {!!registerComplete && (
                        <div>Registration was successful<br />Please login</div>
                    )}
                    {!registerComplete && (
                        <div>
                            <div className="form-group">
                                <TextField
                                    fullWidth
                                    style={{
                                        backgroundColor: "white"
                                    }}
                                    type="text"
                                    name="userName"
                                    label="User Name"
                                    {...register("userName", { required: "User Name is required" })}
                                    aria-invalid={errors.userName ? "true" : "false"}
                                />
                                {errors.userName && <p role="alert" className="alert alert-danger">{errors.userName?.message}</p>}

                            </div>
                            <div>&nbsp;</div>

                            <div className="form-group">
                                <TextField
                                    fullWidth
                                    style={{
                                        backgroundColor: "white"
                                    }}
                                    type="text"
                                    name="email"
                                    label="Email"
                                    {...register("email", {
                                        required: "email is required",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Invalid email address"
                                        }
                                    }
                                    )}
                                    aria-invalid={errors.email ? "true" : "false"}
                                />
                                {errors.email && <p role="alert" className="alert alert-danger">{errors.email?.message}</p>}

                            </div>
                            <div>&nbsp;</div>


                            <div className="form-group">
                                <TextField
                                    fullWidth
                                    style={{
                                        backgroundColor: "white"
                                    }}
                                    type="password"
                                    name="password"
                                    label="Password"
                                    {...register("password", { required: "password is required" })}
                                    aria-invalid={errors.password ? "true" : "false"}
                                />
                                {errors.password && <p role="alert" className="alert alert-danger">{errors.password?.message}</p>}

                            </div>

                            <div className="form-group">
                                <Button variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                    fullWidth
                                    type='submit'
                                >
                                    Sign Up
                                </Button>
                            </div>
                        </div>
                    )}

                    {message && (
                        <div className="form-group">
                            <div
                                className={
                                    registerComplete
                                        ? "alert alert-success"
                                        : "alert alert-danger"
                                }
                                role="alert"
                            >
                                {message}
                            </div>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );

}