import React, { useState } from "react";
import { useForm } from "react-hook-form";
import AuthService from "../services/auth.service";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";
import { useQuery } from "react-query";
import axios from "axios";
import { GetUrlBaseV2 } from "./GetUrlBaseV2";
import { QueryKeys } from "./QueryKeys";
import toast, { Toaster } from 'react-hot-toast';
import { CircularProgress } from '@mui/material';
import getHttpResponseError from "./GetHttpResponseError";
import { alpha, Box, Button, Card, Grid, IconButton, TextField, styled, Switch } from "@mui/material";

const urlBase = GetUrlBaseV2();
const queryKeys = QueryKeys();


function UserProfile(props) {
    const queryClient = useQueryClient();
    const auctionEntityId = AuthService.getAuctionEntityId();
    const [testData] = useState(props.testData);
    const { status, data, error, isFetching } = useAuctionEntity(auctionEntityId, testData);
    const { register, handleSubmit, watch, formState: { errors } } = useForm();

    function onSubmit(values) {
        const dto = {
            "auctionEntityId": auctionEntityId,
            "name": values.name,
            "phoneNumber": values.phoneNumber,
            "creditScore": values.creditScore,
            "maximumAmountToLend": values.maximumAmountToLend,
            "emailAddress": values.emailAddress,
            "addressLine1": values.addressLine1,
            "addressLine2": values.addressLine2,
            "city": values.city,
            "stateOrProvince": values.stateOrProvince,
            "country": values.country,
            "zipCode": values.zipCode
        };
        updateProfileViaApi.mutate(dto);
    }

    const updateProfileViaApi = useMutation(
        (newData) => {
            return axios.put(`${urlBase}/api/UserProfile/${auctionEntityId}`, newData)
        },
        {
            onSuccess: () => {
                /*queryClient.invalidateQueries(["BorrowerComponentLookup", borrowerId]);*/
                toast.success("Your information was updated");
                queryClient.invalidateQueries();
            },
            onError: (error) => {
                const resMessage = getHttpResponseError(error);
                toast.error(resMessage);
            }
        }
    );

    const getAuctionEntityById = async (auctionEntityId) => {
        var target = `${urlBase}/api/UserProfile/${auctionEntityId}`;
        const { data } = await axios.get(
            target
        );
        return data;
    };

    function useAuctionEntity(auctionEntityId, testData) {
        const [intervalMs/*, setIntervalMs*/] = React.useState(10000);
        return useQuery([queryKeys["UserProfile"], auctionEntityId], () => getAuctionEntityById(auctionEntityId), {
            enabled: !!auctionEntityId && !testData && auctionEntityId != "00000000-0000-0000-0000-000000000000",
        }
            ,
            {
                // Refetch the data every minute
                refetchInterval: intervalMs,
            },

        );
    }


    if (isFetching || !auctionEntityId || status === "loading") return <div><CircularProgress /></div>;
    if (error) return <div>Error returned {error}</div>;

    const usedData = !testData ? data : testData;

    if (testData && data) {
        console.log("State error, fetched data while using test data");
    }

    //console.log("data = >" + JSON.stringify(usedData));

    return (
        <>
            <div><Toaster /></div>
            <Box pt={2} pb={4}>
                <Card sx={{ padding: 4 }}>
                    <Grid container spacing={3}>
                        <Grid item md={8} xs={12}>
                            <Card sx={{ padding: 3, boxShadow: 2 }}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container spacing={3}>
                                        <Grid item sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                name="name"
                                                label="Full Name"
                                                type="text"
                                                defaultValue={usedData && usedData.name ? usedData.name : 'NotSet'}
                                                {...register("name", { required: "Name is required" })}
                                                aria-invalid={errors.name ? "true" : "false"}
                                            />
                                            {errors.name && <p role="alert" className="alert alert-danger">{errors.name?.message}</p>}
                                        </Grid>

                                        <Grid item sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                name="emailAddress"
                                                label="Email Address"
                                                type="text"
                                                defaultValue={usedData && usedData.emailAddress ? usedData.emailAddress : ''}
                                                {...register("emailAddress", {
                                                    required: "Email is required",
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                        message: 'Enter a valid email address',
                                                    },
                                                })}
                                                aria-invalid={errors.emailAddress ? "true" : "false"}
                                            />
                                            {errors.emailAddress && <p role="alert" className="alert alert-danger">{errors.emailAddress?.message}</p>}
                                        </Grid>

                                        <Grid item sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                name="creditScore"
                                                label="Credit Score"
                                                type="number"
                                                defaultValue={usedData && usedData.creditScore ? usedData.creditScore : ''}
                                                {...register("creditScore", 
                                                { required: "Credit Score is required - this is ignored for lenders" }
                                                )}
                                                aria-invalid={errors.creditScore ? "true" : "false"}
                                            />
                                            {errors.creditScore && <p role="alert" className="alert alert-danger">{errors.creditScore?.message}</p>}
                                        </Grid>

                                        <Grid item sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                name="maximumAmountToLend"
                                                label="Max Lend Amount"
                                                type="number"
                                                defaultValue={usedData && usedData.maximumAmountToLend ? usedData.maximumAmountToLend : ''}
                                                {...register("maximumAmountToLend", { required: "Max Amount available to lend is required - zero is OK for borrowers" })}
                                                aria-invalid={errors.maximumAmountToLend ? "true" : "false"}
                                            />
                                            {errors.maximumAmountToLend && <p role="alert" className="alert alert-danger">{errors.maximumAmountToLend?.message}</p>}
                                        </Grid>
                                        <Grid item sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                name="addressLine1"
                                                label="AddressLine1"
                                                type="text"
                                                defaultValue={usedData && usedData.addressLine1 ? usedData.addressLine1 : ''}
                                                {...register("addressLine1", { required: "Address Line is required" })}
                                                aria-invalid={errors.addressLine1 ? "true" : "false"}
                                            />
                                            {errors.addressLine1 && <p role="alert" className="alert alert-danger">{errors.addressLine1?.message}</p>}
                                        </Grid>

                                        <Grid item sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                name="addressLine2"
                                                label="AddressLine2"
                                                type="text"
                                                defaultValue={usedData && usedData.addressLine2 ? usedData.addressLine2 : ''}
                                                {...register("addressLine2")}
                                            />
                                        </Grid>

                                        <Grid item sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                name="city"
                                                label="City"
                                                type="text"
                                                defaultValue={usedData && usedData.city ? usedData.city : ''}
                                                {...register("city", { required: "City is required" })}
                                                aria-invalid={errors.city ? "true" : "false"}
                                            />
                                            {errors.city && <p role="alert" className="alert alert-danger">{errors.city?.message}</p>}
                                        </Grid>

                                        <Grid item sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                name="stateOrProvince"
                                                label="State/Province"
                                                type="text"
                                                defaultValue={usedData && usedData.stateOrProvince ? usedData.stateOrProvince : ''}
                                                {...register("stateOrProvince", { required: "State/Province/Region is required" })}
                                                aria-invalid={errors.stateOrProvince ? "true" : "false"}
                                            />
                                            {errors.stateOrProvince && <p role="alert" className="alert alert-danger">{errors.stateOrProvince?.message}</p>}
                                        </Grid>

                                        <Grid item sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                name="zipCode"
                                                label="Zip Code"
                                                type="text"
                                                defaultValue={usedData && usedData.zipCode ? usedData.zipCode : ''}
                                                {...register("zipCode", { required: "Zipcode is required" })}
                                                aria-invalid={errors.zipCode ? "true" : "false"}
                                            />
                                            {errors.zipCode && <p role="alert" className="alert alert-danger">{errors.zipCode?.message}</p>}
                                        </Grid>

                                        <Grid item sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                name="country"
                                                label="Country"
                                                type="text"
                                                defaultValue={usedData && usedData.country ? usedData.country : ''}
                                                {...register("country", { required: "Country is required" })}
                                                aria-invalid={errors.country ? "true" : "false"}
                                            />
                                            {errors.country && <p role="alert" className="alert alert-danger">{errors.country?.message}</p>}
                                        </Grid>


                                        <Grid item sm={6} xs={12}>
                                            <TextField
                                                fullWidth
                                                name="phoneNumber"
                                                label="Phone Number"
                                                type="text"
                                                defaultValue={usedData && usedData.phoneNumber ? usedData.phoneNumber : ''}
                                                {...register("phoneNumber", { required: "Phone number is required" })}
                                                aria-invalid={errors.phone ? "true" : "false"}
                                            />
                                            {errors.phoneNumber && <p role="alert" className="alert alert-danger">{errors.phoneNumber?.message}</p>}
                                        </Grid>



                                        <Grid item xs={12}>
                                            <Button variant="contained" color="success" type='submit'>
                                                Update Profile
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Card>
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </>
    );
};

export default UserProfile;
