/* Formatting Utilities */

import { date } from "yup";

export function FormatPct(num) {
    return Number(num).toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits:2 });
}

var dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

var nearestDollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export function FormatMoney(num) {
    return dollarFormatter.format(num);
}

export function FormatMoneyRounded(num){
    return nearestDollarFormatter.format(num);
}

export function FormatValue(formatOption, value){
    if(formatOption === '$')
        return FormatMoney(value);
    if(formatOption === '%')
        return FormatPct(value);
    if(formatOption === '$$') //no decimals
        return FormatMoneyRounded(value);
    return value+ formatOption; //default to postfix
}

export function GetCurrentTime() {
    let currentDate = new Date();            
    return currentDate.toLocaleTimeString();
}