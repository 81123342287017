import React, { useState } from "react";
import { useForm } from "react-hook-form";
import AuthService from "../services/auth.service";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";
import { useQuery } from "react-query";
import axios from "axios";
import { GetUrlBaseV2 } from "./GetUrlBaseV2";
import { QueryKeys } from "./QueryKeys";
import toast, { Toaster } from 'react-hot-toast';
import { CircularProgress } from '@mui/material';
import getHttpResponseError from "./GetHttpResponseError";
import { alpha, Box, Button, Card, Grid, IconButton, TextField, styled, Switch } from "@mui/material";
//import "../css/Login.css";
//import "../css/UserList.css";


const urlBase = GetUrlBaseV2();
const queryKeys = QueryKeys();


function ResetPassword(props) {
  const queryClient = useQueryClient();
  const auctionEntityId = AuthService.getAuctionEntityId();
  const [testData] = useState(props.testData);
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const { status, data, error, isFetching } = useAuctionEntity(auctionEntityId, testData);


  function onSubmit(values) {
    const dto = {
      "emailAddress": values.emailAddress,
    };
    sendUpdateLinkViaApi.mutate(dto);
  }

  const sendUpdateLinkViaApi = useMutation(
    (newData) => {
      return axios.put(`${urlBase}/api/UserProfile/${auctionEntityId}`, newData)
    },
    {
      onSuccess: () => {
        /*queryClient.invalidateQueries(["BorrowerComponentLookup", borrowerId]);*/
        toast.success("Update link was sent.");
        queryClient.invalidateQueries();
      },
      onError: (error) => {
        const resMessage = getHttpResponseError(error);
        toast.error(resMessage);
      }
    }
  );


  
  function useAuctionEntity(auctionEntityId, testData) {
    const [intervalMs/*, setIntervalMs*/] = React.useState(10000);
    return useQuery([queryKeys["UserProfile"], auctionEntityId], () => getAuctionEntityById(auctionEntityId), {
        enabled: !!auctionEntityId && !testData && auctionEntityId != "00000000-0000-0000-0000-000000000000",
    }
        ,
        {
            // Refetch the data every minute
            refetchInterval: intervalMs,
        },

    );
}


  const getAuctionEntityById = async (auctionEntityId) => {
    var target = `${urlBase}/api/UserProfile/${auctionEntityId}`;
    const { data } = await axios.get(
      target
    );
    return data;
  };


  if (isFetching || status === "loading") return <div><CircularProgress /></div>;
  if (error) return <div>Error returned {error}</div>;

  const usedData = !testData ? data : testData;

  if (testData && data) {
    console.log("State error, fetched data while using test data");
  }

  //console.log("data = >" + JSON.stringify(usedData));

  return (
    <>
      <div><Toaster /></div>



      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />


        <div className="title2">
          &nbsp;
        </div>

        <div className="title2">
          Enter your email address below to get a password reset link
        </div>

        <div className="title2">
          &nbsp;
        </div>




        <form onSubmit={handleSubmit(onSubmit)}>

          <TextField
            fullWidth
            name="emailAddress"
            label="Email Address"
            type="text"
            style={{
              backgroundColor: "white"
            }}
            defaultValue={usedData && usedData.emailAddress ? usedData.emailAddress : ''}
            {...register("emailAddress", {
              required: "Email is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Enter a valid email address',
              },
            })}
            aria-invalid={errors.emailAddress ? "true" : "false"}
          />
          {errors.emailAddress && <p role="alert" className="alert alert-danger">{errors.emailAddress?.message}</p>}

          <div className="form-group">
            <Button variant="contained"
              sx={{ mt: 3, mb: 2 }}
              fullWidth
              type="submit"
            >
              <span>Get Reset Link</span>
            </Button>
          </div>
        </form>
      </div>

    </>
  );
};

export default ResetPassword;
