import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import axios from "axios";
import { useQuery } from "react-query";
import { useQueryClient } from "react-query";
import { useParams } from 'react-router-dom'
import DataTable from './DataTable';
import { InitDataTable } from './DataTable';
import { AddDataTableField } from './DataTable';
import { FormatMoneyRounded, FormatPct } from './FormattingUtilities';
import "../css/LendingComponent.css";
import "../css/UserList.css";
import { GetUrlBaseV2 } from "./GetUrlBaseV2";
import { QueryKeys } from "./QueryKeys";
import { CircularProgress } from "@mui/material";



function AuctionItems(props) {
    const handle = useParams();
    const [auctionId, setAuctionId] = React.useState(handle.auctionId);
    const queryClient = useQueryClient();
    const urlBase = GetUrlBaseV2();
    const queryKeys = QueryKeys();
    const { status, data, error /*, isFetching*/ } = useAuction(auctionId);

    const getAuctionItemsByAuctionId = async (id) => {
        const { data } = await axios.get(
            `${urlBase}/api/AuctionItem?auctionId=${id}`
        );
        return data;
    };

    function useAuction(auctionId) {
        const [intervalMs/*, setIntervalMs*/] = React.useState(1000);
        return useQuery([queryKeys["AuctionItems"], auctionId], () => getAuctionItemsByAuctionId(auctionId), {
            enabled: !!auctionId,
        }
            ,
            {
                // Refetch the data every minute
                refetchInterval: intervalMs,
            },

        );
    }

    if (!auctionId || status === "loading")     return <div><CircularProgress  /></div> ;
    /*if (isFetching) return "Background Fetch...";*/
    if (error) return "An error has occurred: " + error.message;


    let columns = prepareDataTable();
    let rows = formatRows(data);

    function prepareDataTable() {
        let baseColumns = InitDataTable();
        baseColumns = AddDataTableField(baseColumns, 'id', 'id', 120, '', false, '');
        baseColumns = AddDataTableField(baseColumns, 'auctionItemTypeId', 'Action', 90, 'Number', true, '');
        baseColumns = AddDataTableField(baseColumns, 'userId', 'auctionEntityId', 120, '', false, '');
        baseColumns = AddDataTableField(baseColumns, 'nickName', 'NickName', 200, '', false, '');
        baseColumns = AddDataTableField(baseColumns, 'inPool', 'In Pool', 130, 'number', true, '');
        baseColumns = AddDataTableField(baseColumns, 'timeSpan', 'Time Span', 130, 'number', true, '');
        baseColumns = AddDataTableField(baseColumns, 'percentage', 'Percentage', 90, 'number', true, '');
        baseColumns = AddDataTableField(baseColumns, 'amount', 'Amount', 90, 'number', true, '');
        baseColumns = AddDataTableField(baseColumns, 'createDateTime', 'Created On', "200", 'number', true, '');
        return baseColumns;   
    }

    function formatRows(rows) {
        if(!Array.isArray(rows)){
            console.log('AuctionItems.formatRows did not receive row data');
            return [];
        }
        let result = rows.map(row => ({
            id: row.auctionItemId,
            userId: row.auctionEntityId,
            nickName: row.nickName,
            auctionItemTypeId: row.auctionItemTypeId == 1 ? 'Borrow' : 'Lend',
            inPool: row.inPool == 1? 'Yes': 'No',            
            timeSpan: row.auctionItemTypeId == 1 ? row.currentTimeSpan + ' Months' : 'N/A',
            percentage: FormatPct(row.currentPercentage),
            amount: FormatMoneyRounded(row.amount),
            createDateTime: row.createDateTime
        }));
        return result;
    }

    return (            
        <div >
            <div className="title1">Management Dashboard List of Auction Items - V2</div>
            <div className="top-spacer">&nbsp;</div>
            <div style={{ display: 'flex', height: '100%', width: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataTable rows={rows} columns={columns} showToolbar={true} />
                </div>
            </div>
            <div className="bottom-spacer">&nbsp;</div>
        </div>
    );
} /* end render()*/

export default AuctionItems;