import React from 'react';
import { useTitle } from './UseTitle';
import { useParams } from 'react-router-dom'
import AreaChartComponent from "./AreaChartComponent";
import "../css/ShimmerBox.css";
import { createTheme } from '@mui/material';
import BuildDate from "./BuildDate";
import "../css/Poppins.css";





function About(props) {
    const handle = useParams();
    const Poppins = createTheme({
        typography: {
            fontFamily: [
                'Poppins',
            ].join(','),
        },
    });


    var version = "2024-01-13.001";
    var title = 'TLP Version ' + version; 
    useTitle(title);

    console.info(BuildDate());
    console.info(title);

    return <div >
        <div id='a' className='bigPadding'>
            <div id='b'>The Lending Pool is the future of money exchange</div>
            <div id='bb'>
                This is build version {title}
            </div>

            <div id='c'>
                &nbsp;
            </div>

            <div id='d' className="bigPadding" >
                <div id='e'>Here below is a sample chart</div>
                <div id='f'>
                    &nbsp;
                </div>
            </div>
            <div id='g' className="bigPadding">
                <AreaChartComponent />
            </div>
        </div>

    </div>
}
export default About;
