
export default function getHttpResponseError(error){
    try{
    let msg = error;
    let type = 'error';
    if (error.response) {
        msg = error.response;
        type = 'error.response';
        if (error.response.data) {
            msg = error.response.data;
            type = 'error.response.data';
            if (error.response.data.message) {
                msg = error.response.data.message;
                type = 'error.response.data.message';
            }
        }
    }
    return msg;
    } catch(e){
        return 'Could not determine error due to ' + e;
    }
}